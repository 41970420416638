import { FunctionComponent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { AdminNav, Icon, AdminButtonRow, UserAccordion } from "../components";
import "./ListPages.css";
import { useAdminNav } from "../hooks/useAdminNav";
import ListHeader from '../components/ListHeader';
import UserManager from '../models/UserManager';
import { User } from '../types';
import { FeaturesManager, IFeatures } from '../models/FeaturesManager';
import { Switch, FormControlLabel } from '@mui/material';

const UserAccessView: FunctionComponent = observer(() => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");
    const [users, setUsers] = useState<User[]>([]);
    const [globalFeatures, setGlobalFeatures] = useState<IFeatures>({
        enableInAppRecording: false,
        enableWander: false
    });

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const allUsers = await UserManager.getAllUsers();
                setUsers(allUsers);
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        };

        const fetchFeatures = async () => {
            try {
                const features = await FeaturesManager.getFeatures();
                setGlobalFeatures(features);
            } catch (error) {
                console.error("Error fetching features:", error);
            }
        };

        fetchUsers();
        fetchFeatures();

        // Set up listener for feature changes
        const unsubscribe = FeaturesManager.listenToFeatures((features) => {
            setGlobalFeatures(features);
        });

        // Cleanup listener on component unmount
        return () => unsubscribe();
    }, []);

    const handleFeatureToggle = async (feature: keyof IFeatures) => {
        try {
            const newValue = !globalFeatures[feature];
            await FeaturesManager.updateFeature(feature, newValue);
            // The local state will be updated by the listener we set up in useEffect
        } catch (error) {
            console.error(`Error updating ${feature}:`, error);
            alert(`Failed to update ${feature}. Please try again.`);
        }
    };

    const filteredUsers = users
        .filter(user =>
            user.userProfile.userName.toLowerCase() === searchTerm.toLowerCase() ||
            user.userKey?.toLowerCase() === searchTerm.toLowerCase()
        )
        .reduce<User[]>((uniqueUsers, user) => {
            if (!uniqueUsers.some(u => u.userKey === user.userKey)) {
                uniqueUsers.push(user);
            }
            return uniqueUsers;
        }, []);

    const {
        projectName,
        projects,
        onProjectChange,
        selectedProject,
        userRole
    } = useAdminNav();

    return (
        <div className="list-page">
            <AdminNav
                showProjectDropdown={false}
                imageDimensions="/assets/overhear-assets/images/ovh-logoartboard-12x-1.png"
                projectAdminHeight="unset"
                projectAdminPosition="unset"
                projectAdminTop="unset"
                projectAdminLeft="unset"
                projectName={projectName}
                userRole={userRole}
                projects={projects}
                selectedProject={selectedProject}
                onProjectChange={onProjectChange}
            />
            <AdminButtonRow
                settingsIcon={<Icon icon="settings" size={24} />}
                handleAdminMessageViewClick={() => navigate("/message-view")}
                handleProjectViewClick={() => navigate("/project-view")}
                handleWandersViewClick={() => navigate("/wanders-view")}
                handleUserAccessViewClick={() => navigate("/user-access-view")}
                defaultView="project-view"
                userRole={userRole}
            />
            <div className="search-and-features-container">
                <input
                    type="text"
                    placeholder="Search users..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-bar"
                />
                <div className="global-features">
                    <span className="global-features-title">Global Feature Switches:</span>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={globalFeatures.enableInAppRecording}
                                onChange={() => handleFeatureToggle('enableInAppRecording')}
                                name="enableInAppRecording"
                            />
                        }
                        label="In-App Recording"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={globalFeatures.enableWander}
                                onChange={() => handleFeatureToggle('enableWander')}
                                name="enableWander"
                            />
                        }
                        label="Wander"
                    />
                </div>
            </div>
            <ListHeader headers={['User Name','User Key', 'In-App Recordings', 'Wanders']} columnCount={4} />
            {filteredUsers.map((user) => (
                <UserAccordion
                    key={user.userKey}
                    user={user}
                />
            ))}
        </div>
    );
});

export default UserAccessView;