import React, { useState } from 'react';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { TextField, InputAdornment } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import { auth } from '../firebase/firebase';
import UserManager from '../models/UserManager';
import AuthorManager from '../models/AuthorManager';
import { v4 as uuidv4 } from 'uuid';
import './FormLogin.css';
import { colors } from '../theme/colors';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase';

interface FormAuthorProps {
  onAccountExists: () => void;
  onSuccess?: () => void;
  initialAuthorName: string;
  isModal?: boolean;
  initialIsLogin?: boolean;
}

export const FormAuthor: React.FC<FormAuthorProps> = ({
  onAccountExists,
  onSuccess,
  initialAuthorName,
  isModal = false,
  initialIsLogin = false,
}) => {
  const [isLogin, setIsLogin] = useState(initialIsLogin);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState(initialAuthorName);
  const [error, setError] = useState('');
  const [showNamePrompt, setShowNamePrompt] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    

    if (!isLogin && password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      let user;
      let userName = '';
      if (isLogin) {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        user = userCredential.user;
        

        // Check if user has a username
        const userDoc = await getDoc(doc(db, 'Users', user.uid));
        const userData = userDoc.data();
        if (!userData || !userData.userProfile || !userData.userProfile.userName || userData.userProfile.userName.trim() === '') {
          setShowNamePrompt(true);
          return;
        } else {
          userName = userData.userProfile.userName;
        }
      } else {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        user = userCredential.user;
        
        userName = name.trim();
      }

      if (user) {
        const currentUser = await UserManager.getCurrentUser(user.uid);
        
        if (!currentUser || !currentUser.userProfile.authorKey) {
          const authorKey = uuidv4();
          await UserManager.createUser({
            userKey: user.uid,
            userProfile: {
              userName: userName,
              profileImage: '',
              bio: '',
              expoPushToken: '',
              restrictAutoAdd: false,
              authorKey,
            },

          });
          

          if (!isLogin) {
            const newAuthorData = {
              name: userName,
              userKey: user.uid,
              tags: { authorTags: [] },
              isClaimed: true,
            };
            await AuthorManager.createAuthor(authorKey, newAuthorData);
            
          }
        }

        const potentialAuthors = await AuthorManager.getAuthorsByName(userName);
        if (potentialAuthors.length > 0) {
          navigate('/author-claim-process', { state: { name: userName, userKey: user.uid, authorKey: currentUser?.userProfile.authorKey } });
        } else {
          navigate('/author-dashboard');
        }

        if (onSuccess) {
          onSuccess();
        }
      }
    } catch (error: any) {
      console.error('Error during process:', error);
      if (error.code === 'auth/email-already-in-use') {
        onAccountExists();
      } else {
        setError(isLogin ? 'Failed to login. Please try again.' : 'Failed to create account. Please try again.');
      }
    }
  };

  const handleNameSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (name.trim() === '') {
      setError('Please enter a valid name');
      return;
    }
    try {
      const user = auth.currentUser;
      if (user) {
        await UserManager.updateUserProfile(user.uid, { userName: name.trim() });
        setShowNamePrompt(false);
        
        // Check for potential authors after setting the username
        const potentialAuthors = await AuthorManager.getAuthorsByName(name.trim());
        if (potentialAuthors.length > 0) {
          navigate('/author-claim-process', { state: { name: name.trim(), userKey: user.uid } });
        } else {
          navigate('/author-dashboard');
        }
        
        if (onSuccess) {
          onSuccess();
        }
      }
    } catch (error) {
      console.error('Error updating username:', error);
      setError('Failed to update username. Please try again.');
    }
  };

  const toggleMode = () => {
    setIsLogin(!isLogin);
    setError('');
  };

  if (showNamePrompt) {
    return (
      <form onSubmit={handleNameSubmit} className="login-form">
        <h2 className="form-title" style={{ color: colors.palette.neutral100 }}>
          Set Your Author Name
        </h2>
        <TextField
          sx={{
            backgroundColor: "var(--color-lightsteelblue)",
            alignSelf: "stretch",
            borderRadius: "var(--br-8xs)",
            padding: "0 var(--padding-2xl)",
            boxSizing: "border-box",
            '& .MuiOutlinedInput-root': {
              fontSize: "var(--font-size-base)",
              fontFamily: "var(--font-manrope)",
              border: 0,
              fontWeight: 500,
              height: "50px",
              display: "flex",
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none', 
            },
          }}
          placeholder="Author Name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon />
              </InputAdornment>
            ),
          }}
        />
        {error && <p className="error-message" style={{ color: 'red' }}>{error}</p>}
        <button type="submit" className="login-button">
          <span className="login-text">Set Author Name</span>
        </button>
      </form>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="login-form">
      <h2 className="form-title" style={{ color: colors.palette.neutral100 }}>
        {isLogin ? 'Author Login' : 'Author Sign Up'}
      </h2>
      {!isLogin && (
        <TextField
          sx={{
            backgroundColor: "var(--color-lightsteelblue)",
            alignSelf: "stretch",
            borderRadius: "var(--br-8xs)",
            padding: "0 var(--padding-2xl)",
            boxSizing: "border-box",
            '& .MuiOutlinedInput-root': {
              fontSize: "var(--font-size-base)",
              fontFamily: "var(--font-manrope)",
              border: 0,
              fontWeight: 500,
              height: "50px",
              display: "flex",
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none', 
            },
          }}
          placeholder="Name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
      <TextField
        sx={{
          backgroundColor: "var(--color-lightsteelblue)",
          alignSelf: "stretch",
          borderRadius: "var(--br-8xs)",
          padding: "0 var(--padding-2xl)",
          boxSizing: "border-box",
          '& .MuiOutlinedInput-root': {
            fontSize: "var(--font-size-base)",
            fontFamily: "var(--font-manrope)",
            border: 0,
            fontWeight: 500,
            height: "50px",
            display: "flex",
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none', 
          },
        }}
        placeholder="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        sx={{
          backgroundColor: "var(--color-lightsteelblue)",
          alignSelf: "stretch",
          borderRadius: "var(--br-8xs)",
          padding: "0 var(--padding-2xl)",
          boxSizing: "border-box",
          '& .MuiOutlinedInput-root': {
            fontSize: "var(--font-size-base)",
            fontFamily: "var(--font-manrope)",
            border: 0,
            fontWeight: 500,
            height: "50px",
            display: "flex",
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none', 
          },
        }}
        placeholder="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon />
            </InputAdornment>
          ),
        }}
      />
      {!isLogin && (
        <TextField
          sx={{
            backgroundColor: "var(--color-lightsteelblue)",
            alignSelf: "stretch",
            borderRadius: "var(--br-8xs)",
            padding: "0 var(--padding-2xl)",
            boxSizing: "border-box",
            '& .MuiOutlinedInput-root': {
              fontSize: "var(--font-size-base)",
              fontFamily: "var(--font-manrope)",
              border: 0,
              fontWeight: 500,
              height: "50px",
              display: "flex",
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none', 
            },
          }}
          placeholder="Confirm Password"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
      {error && <p className="error-message" style={{ color: 'red' }}>{error}</p>}
      <button type="submit" className="login-button">
        <span className="login-text">{isLogin ? 'Log In' : 'Sign Up'} as Author</span>
      </button>
      <p className="toggle-link" style={{ color: colors.palette.neutral100, marginTop: '10px', textAlign: 'center' }}>
        {isLogin ? "Don't have an account? " : "Already have an account? "}
        <a onClick={toggleMode} style={{ color: colors.palette.neutral100, textDecoration: 'underline', cursor: 'pointer' }}>
          {isLogin ? 'Sign Up' : 'Log In'}
        </a>
      </p>
    </form>
  );
};
