import { TagManager } from '../models/TagManager';
import { IRecordingWithAuthor, IRecording } from '../types';

export const updateTags = async (
  recording: IRecordingWithAuthor,
  authorTags: string[],
  subjectTags: string[],
  genreTags: string[],
  pinKey: string | undefined,
  initialAuthorTags: string[]
) => {
  

  const authorKey = recording?.author?.authorKey;
  const recordingKey = recording?.key;

  if (!authorKey || !recordingKey || !pinKey) {
    console.error('Missing required keys for updating tags.');
    return;
  }

  const oldAuthorTags = initialAuthorTags;
  const oldSubjectTags = recording?.tags?.subjectTags || [];
  const oldGenreTags = recording?.tags?.genreTags || [];




  const removedAuthorTags = oldAuthorTags.filter(tag => !authorTags.includes(tag));



  const removedSubjectTags = oldSubjectTags.filter(tag => !subjectTags.includes(tag));

  const addedGenreTags = genreTags.filter(tag => !oldGenreTags.includes(tag));
  const removedGenreTags = oldGenreTags.filter(tag => !genreTags.includes(tag));


  // Update tags in Firestore

  await TagManager.updateAuthorTags(authorKey, authorTags, oldAuthorTags, pinKey);


  await TagManager.updateRecordingTags(recordingKey, subjectTags, oldSubjectTags, genreTags, oldGenreTags, pinKey);

  // Remove the authorKey from the removed author tags
  for (const tag of removedAuthorTags) {

    await TagManager.removeAuthorKeyFromTag(tag, authorKey, pinKey);
  }

  // Remove the recordingKey from the removed subject tags
  for (const tag of removedSubjectTags) {
    
    await TagManager.removeRecordingKeyFromTag(tag, recordingKey, pinKey);
  }

  // Move the recordingKey between the relevant documents for genre tags
  for (const tag of removedGenreTags) {
    
    await TagManager.removeRecordingKeyFromGenreTag(tag, recordingKey, pinKey);
  }
  for (const tag of addedGenreTags) {
    
    await TagManager.addRecordingKeyToGenreTag(tag, recordingKey, pinKey);
  }

  
};

export const addTagsToNewRecording = async (
  newRecording: IRecording,
  authorTags: string[],
  subjectTags: string[],
  genreTags: string[],
  pinKey: string | undefined
) => {
  const authorKey = newRecording?.ownership?.recordingAuthor;
  const recordingKey = newRecording?.key;

  if (!authorKey) {
    console.error('Missing authorKey for adding tags.');
    return;
  }
  if (!recordingKey) {
    console.error('Missing recordingKey for adding tags.');
    return;
  }
  if (!pinKey) {
    console.error('Missing pinKey for adding tags.');
    return;
  }

  // Add authorTags to the authorTags subcollection
  for (const tagName of authorTags) {
    await TagManager.addAuthorTagToAuthor(authorKey, tagName, pinKey);
  }

  // Add subjectTags to the recordingTags subcollection
  for (const tagName of subjectTags) {
    await TagManager.addSubjectTagToRecording(recordingKey, tagName, pinKey);
  }

  // Add genreTags to the recordingTags subcollection
  for (const genreTag of genreTags) {
    await TagManager.addRecordingKeyToGenreTag(genreTag, recordingKey, pinKey);
  }
};