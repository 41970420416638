import React, { useState } from "react";
import { FormAuthor } from "../components";
import "./LoginScreen.css";

const AuthorLoginScreen: React.FC = () => {
  const [isLogin, setIsLogin] = useState(false);
  const [accountExistsMessage, setAccountExistsMessage] = useState('');

  const handleAccountExists = () => {
    setIsLogin(true);
    setAccountExistsMessage('An account with this email already exists. Please log in.');
  };



  return (
    <div className="loginscreen">
      <div className="login-content">
        <img
          className="logo-container-icon"
          alt="Overhear Logo"
          src="/assets/overhear-assets/images/ovh-logoartboard-12x-1.png"
        />
        <div className="form-wrapper">
          {accountExistsMessage && (
            <p className="error-message" style={{ color: 'red', textAlign: 'center' }}>
              {accountExistsMessage}
            </p>
          )}
          <FormAuthor 
            onAccountExists={handleAccountExists}
            initialAuthorName=""
            isModal={false}
            initialIsLogin={isLogin}
          />
        </div>
      </div>
    </div>
  );
};

export default AuthorLoginScreen;