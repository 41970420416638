import React, { FunctionComponent, useCallback, useEffect, useState, useRef, useContext } from "react";
import { v4 as uuidv4 } from 'uuid';
import { handleFileUpload, constructUrl } from "../utilities";
import { RecordingManager, AuthorManager, ProjectManager, TagManager } from "../models";
import { IRecording, IRecordingWithAuthor, IAuthor } from "../types";
import UserManager from "../models/UserManager";
import RecordingStore from '../stores/RecordingStore';
import QRCode from 'qrcode';
import { initiateTranscription } from '../services/transcriptionService';
import { sendEmailNotification } from '../services/emailService';
import RecordingForm from './RecordingForm';
import { handleDelete } from "../utilities/handleDelete";
import { updateTags, addTagsToNewRecording } from '../utilities/tagUpdate';
import './EditRecording.css';
import authenticationStore from "../stores/AuthenticationStore";
import { AuthModal } from '../components';
import { observer } from 'mobx-react-lite';
import { projectStore } from '../stores/ProjectStore';
import L from 'leaflet';
import { deleteObject, ref } from 'firebase/storage';
import { storage } from '../firebase'; // Make sure this import is correct for your project structure

type RecordingEditorProps = {
  onClose: () => void;
  recordingKey?: string;
  recording?: IRecordingWithAuthor;
  pinKey?: string;
  pinType?: string;
  albumKey?: string | null;
  sourceView: 'PinList' | 'WanderView' | 'Form' | 'SideBar';
  onRecordingAdded?: () => void;
  onSave?: (updatedRecording: IRecordingWithAuthor) => Promise<void>;
  config: {
    locationInfo: boolean;
    authorInfo: boolean;
    recordingInfo: boolean;
    uploadFields: boolean;
  };
  project_id?: string;
  prefillAuthorData?: IAuthor | null;
  skipAuthCheck?: boolean;
};

type AuthorDetails = {
  authorName: string;
  authorBio: string;
  authorWebsite: string;
  authorTags: string[];
  authorImageUrl: string;
  userKey: string;
};

export const RecordingEditor: FunctionComponent<RecordingEditorProps> = observer(({
  onClose,
  recordingKey,
  recording,
  pinKey = '',
  pinType = '',
  albumKey = null,
  sourceView,
  onRecordingAdded,
  onSave,
  config,
  project_id,
  prefillAuthorData,
  skipAuthCheck = false,
}) => {
  console.log('RecordingEditor initialized with props:', {
    recordingKey,
    recording,
    pinKey,
    pinType,
    albumKey,
    sourceView,
    config,
    project_id,
    prefillAuthorData,
    skipAuthCheck
  });

  const isEditing = !!recordingKey;

  const initialButtonState = (): 'mp3' | 'url' | 'record' => {
    if (recording?.file?.audioURL) {
      return 'url';
    } else if (recording?.file?.recordingPath) {
      return 'mp3';
    }
    return 'mp3'; // Default to 'mp3' if no existing data
  };

  const [selectedButton, setSelectedButton] = useState<'mp3' | 'url' | 'record'>(initialButtonState());
  const [authorDetails, setAuthorDetails] = useState<AuthorDetails>({
    authorName: recording?.author?.name || '',
    authorBio: recording?.author?.bio || '',
    authorWebsite: recording?.author?.website || '',
    authorTags: recording?.author?.tags?.authorTags || [],
    authorImageUrl: recording?.author?.image ? constructUrl(recording.author.image) : '/assets/icons/user.svg',
    userKey: authenticationStore.user?.uid || '',
  });
  const [recordingDetails, setRecordingDetails] = useState({
    recordingURL: recording?.file?.audioURL || '',
    fileSize: recording?.file?.size,
    fileName: recording?.file?.recordingPath ? recording.file.recordingPath.split('/').pop() || undefined : undefined,
    transcription: recording?.transcription || '',
    title: recording?.file?.title || '',
    description: recording?.file?.description || '',
    narrator: recording?.ownership?.recordingNarrator || '',
    subjectTags: recording?.tags?.subjectTags || [],
    genreTags: recording?.tags?.genreTags || [],
    whereQRFind: recording?.whereQRFind,
    selectedButton: initialButtonState(),
  });
  const [availableGenreTags, setAvailableGenreTags] = useState<string[]>([]);
  const [initialAuthorTags, setInitialAuthorTags] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedImageFile, setSelectedImageFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [locationDescription, setLocationDescription] = useState('');
  const [, setQrPath] = useState<string>(recording?.qrPath || '');
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [retainedAuthorDetails, setRetainedAuthorDetails] = useState<AuthorDetails | null>(null);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [markerPosition, setMarkerPosition] = useState<L.LatLng | null>(null);
  const [error, setError] = useState<string | undefined>(undefined); // Change type to string | undefined

  const selectedProject = projectStore.projects.find(p => p.project_id === projectStore.selectedProject)
    || projectStore.projects[0]
    || { icon: '/public/assets/overhear-assets/images/ovh-logoartboard-12x-1.png' };


  const projectIconUrl = selectedProject?.icon
    ? constructUrl(selectedProject.icon)
    : '/public/assets/overhear-assets/images/ovh-logoartboard-12x-1.png';

  const logs: string[] = []; // Initialize an array to capture logs

  useEffect(() => {
    logs.push('Fetching projects...');
    if (projectStore.projects.length === 0) {
      projectStore.fetchProjects();
    }
  }, []);


  useEffect(() => {
    logs.push(`Recording changed: ${JSON.stringify(recording)}`);
    if (recording?.author) {
      setAuthorDetails({
        authorName: recording.author.name || '',
        authorBio: recording.author.bio || '',
        authorWebsite: recording.author.website || '',
        authorTags: recording.author.tags?.authorTags || [],
        authorImageUrl: recording.author.image ? constructUrl(recording.author.image) : '/assets/icons/user.svg',
        userKey: recording.author.userKey || '',
      });
      if (recording.author.tags?.authorTags) {
        const initialAuthorTags = Array.isArray(recording.author.tags.authorTags)
          ? recording.author.tags.authorTags
          : [];
        setInitialAuthorTags(initialAuthorTags);
      }
    }
    if (recording?.tags) {
      setRecordingDetails(prevDetails => ({
        ...prevDetails,
        subjectTags: recording.tags?.subjectTags || [],
        genreTags: recording.tags?.genreTags || [],
      }));
    }
    // Add this log to check the initial values
  }, [recording]);


  useEffect(() => {
    console.log('Fetching genre tags...');
    const fetchGenreTags = async () => {
      try {
        const adminTags = await TagManager.observeAdminTags();
        console.log('Fetched genre tags:', adminTags);
        const fetchedGenreTags = adminTags.length > 0 ? adminTags[0].genreTags : [];
        setAvailableGenreTags(fetchedGenreTags);
      } catch (error) {
        console.error('Error fetching genre tags:', error);
      }
    };
    fetchGenreTags();
  }, []);

  const handleAuthorDetailsChange = useCallback((details: Partial<{
    authorName: string;
    authorBio: string;
    authorWebsite: string;
    authorTags: string[];
    imageFile?: File | null;
  }>) => {
    setAuthorDetails(prevDetails => {
      const newDetails = {
        ...prevDetails,
        ...details,
        authorImageUrl: details.imageFile ? URL.createObjectURL(details.imageFile) : prevDetails.authorImageUrl
      };
      return newDetails;
    });
    if (details.imageFile) {
      setSelectedImageFile(details.imageFile);
    }
  }, []);

  const handleRecordingDetailsChange = useCallback((details: Partial<typeof recordingDetails> & { file?: File, selectedButton?: 'mp3' | 'url' | 'record' }) => {
    setRecordingDetails(prevDetails => {
      const newDetails = {
        ...prevDetails,
        ...details,
        selectedButton: details.selectedButton || prevDetails.selectedButton,
      };
      return newDetails;
    });

    if (details.file) {
      setSelectedFile(details.file);
      setRecordingDetails(prevDetails => ({
        ...prevDetails,
        fileName: details.file?.name
      }));
    }
  }, []);

  const handleLocationDescriptionChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setLocationDescription(value);

    if (value.length >= 5) {  // Assuming a minimum length for a valid postcode or address
      try {
        const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(value)}`);
        const data = await response.json();
        if (data && data.length > 0) {
          const { lat, lon } = data[0];
          // setMapVisible(true);
          // initializeMap(parseFloat(lat), parseFloat(lon));
        }
      } catch (error) {
        console.error('Error geocoding address:', error);
      }
    }
  };

  const generateAndUploadQRCode = async (data: string): Promise<string> => {
    const qrCode = await QRCode.toDataURL(data, {
      errorCorrectionLevel: 'H',
      type: 'image/jpeg',
      renderer: {
        quality: 0.92
      }
    });
    return qrCode;
  };

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSave = async () => {
    logs.push('Save initiated');
    setError(undefined);
    if (!skipAuthCheck && !authenticationStore.isAuthenticated) {
      logs.push('User not authenticated, showing auth modal');
      setShowAuthModal(true);
      return;
    }

    // Add validation for required fields
    if (config.locationInfo && sourceView === 'Form' && !locationDescription.trim()) {
      alert('Location Information is required.');
      return;
    }

    if (config.authorInfo && !authorDetails.authorName.trim()) {
      alert('Author Name is required.');
      return;
    }

    if (config.recordingInfo && !recordingDetails.title.trim()) {
      alert('Recording Title is required.');
      return;
    }

    setIsUploading(true);
    let authorUpdates: Record<string, any> = {};
    let updatedRecording: IRecording | null = null;
    try {
      let authorKey = recording?.author?.authorKey;
      let newAuthorImagePath = recording?.author?.image || '/assets/icons/user.svg';
      let newRecordingPath = '';
      let transcription = '';
      let timingData = {};

      // Use project_id prop if sourceView is 'Form', otherwise use selectedProject from context
      const selectedProject = sourceView === 'Form' ? project_id : projectStore.selectedProject;

      if (!selectedProject) {
        throw new Error('No project selected');
      }

      // Check if the user is logged in and if we're not in PinListView
      const userId = authenticationStore.user?.uid;
      let currentUserKey = null;
      if (userId && sourceView !== 'PinList') {
        const currentUser = await UserManager.getCurrentUser(userId);
        if (currentUser && currentUser.userKey) {
          currentUserKey = currentUser.userKey;
        }
      }
      // Handle author image upload if a new image is selected
      if (selectedImageFile) {
        logs.push('Uploading author image...');
        const timestampedFileName = `${Date.now()}-${selectedImageFile.name}`;
        const setAuthorImageUrl: React.Dispatch<React.SetStateAction<string>> = (urlOrUpdater) => {
          setAuthorDetails(prev => ({
            ...prev,
            authorImageUrl: typeof urlOrUpdater === 'function' ? urlOrUpdater(prev.authorImageUrl) : urlOrUpdater
          }));
        };
        newAuthorImagePath = await handleFileUpload(selectedImageFile, setAuthorImageUrl, `images/author/`, timestampedFileName);
      }

      // Update or create author
      logs.push('Updating or creating author...');
      if (authorKey) {
        authorUpdates = {}; // Initialize authorUpdates
        if (authorDetails.authorName !== recording?.author?.name) authorUpdates.name = authorDetails.authorName || null;
        if (authorDetails.authorBio !== recording?.author?.bio) authorUpdates.bio = authorDetails.authorBio || null;
        if (authorDetails.authorWebsite !== recording?.author?.website) authorUpdates.website = authorDetails.authorWebsite || null;

        // Only update the image if a new image was selected
        if (selectedImageFile) {
          authorUpdates.image = newAuthorImagePath;
        } else {
          // Retain the existing image if no new image was selected
          authorUpdates.image = recording?.author?.image || null;
        }

        // Only update authorTags if they have changed and are not empty
        if (authorDetails.authorTags.length > 0 && authorDetails.authorTags !== recording?.author?.tags?.authorTags) {
          authorUpdates.tags = { authorTags: authorDetails.authorTags.filter(tag => tag !== undefined) }; // Filter out undefined values
        }

        // Remove any undefined values
        Object.keys(authorUpdates).forEach(key => {
          if (authorUpdates[key] === undefined) {
            delete authorUpdates[key];
          }
        });

        if (Object.keys(authorUpdates).length > 0) {
          try {
            await AuthorManager.updateAuthor(authorKey, authorUpdates);
          } catch (authorUpdateError) {
            console.error('RecordingEditor: Error updating author:', authorUpdateError);
            // Continue with the save process even if author update fails
          }
          RecordingStore.updateRecordingAuthor(recordingKey!, authorUpdates);
        }
      } else {
        authorKey = uuidv4();
        const newAuthorData = {
          authorKey: authorKey,
          name: authorDetails.authorName,
          bio: authorDetails.authorBio,
          website: authorDetails.authorWebsite,
          image: selectedImageFile ? newAuthorImagePath : null,
          tags: {
            authorTags: authorDetails.authorTags.filter(tag => tag !== undefined),
          },
          isClaimed: sourceView !== 'PinList', // Set to false for PinList view
          // Only add userKey if not called from PinListView
          ...(sourceView !== 'PinList' && { userKey: authenticationStore.user?.uid }),
        };

        await AuthorManager.createAuthor(authorKey, newAuthorData);
      }

      // Handle recording file upload if a new file is selected
      logs.push('Uploading recording file...');
      if (selectedFile) {
        const timestampedFileName = `${Date.now()}-${recording?.file?.title}`;
        
        // Delete the old recording file if it exists
        if (isEditing && recording?.file?.recordingPath) {
          const oldFileRef = ref(storage, recording.file.recordingPath);
          try {
            await deleteObject(oldFileRef);
            logs.push('Old recording file deleted successfully');
          } catch (deleteError) {
            console.error('Error deleting old recording file:', deleteError);
            logs.push(`Error deleting old recording file: ${deleteError}`);
            // Continue with the upload even if deletion fails
          }
        }

        newRecordingPath = await handleFileUpload(
          selectedFile,
          () => { },
          `recordings/${selectedProject}/`,
          timestampedFileName
        );
      }

      // Generate and upload QR code if pinType is 'qrCode'
      logs.push('Generating QR code...');
      let qrCodeImagePath = '';
      if (pinType === 'qrCode') {
        qrCodeImagePath = await generateAndUploadQRCode(recordingKey || uuidv4());
        setQrPath(qrCodeImagePath);
      }

      // Prepare the recording object
      logs.push('Preparing recording object...');
      updatedRecording = {
        key: recordingKey || uuidv4(),
        albumKey: albumKey || null,
        qrPath: qrCodeImagePath || '',
        file: {
          description: recordingDetails.description || '',
          audioURL: selectedButton === 'url' ? recordingDetails.recordingURL : '',
          recordingPath: newRecordingPath || recording?.file?.recordingPath || '',
          title: recordingDetails.title || '',
          size: recordingDetails.fileSize || 0,
        },
        ownership: {
          recordingNarrator: recordingDetails.narrator || '',
          recordingAuthor: authorKey || '',
          recordingOwner: currentUserKey || '',
        },
        project: sourceView === 'WanderView' ? 'Wanders' : selectedProject,
        pinKey: pinKey || '',
        tags: {
          subjectTags: recordingDetails.subjectTags.filter(tag => tag !== undefined), // Filter out undefined values
          genreTags: recordingDetails.genreTags.filter(tag => tag !== undefined), // Filter out undefined values
        },
        whereQRFind: recordingDetails.whereQRFind || '',  // Ensure this field is not undefined
        transcription: recordingDetails.transcription || '', // Use the current transcription
        timingData: recording?.timingData || {} // Retain existing timingData or use an empty object
      };

      // Check for undefined values in updatedRecording
      Object.keys(updatedRecording).forEach(key => {
        if ((updatedRecording as any)[key] === undefined) {
          delete (updatedRecording as any)[key];
        }
      });

      // Update or create recording
      logs.push('Updating or creating recording...');
      try {
        if (isEditing) {
          await RecordingManager.updateRecording(recordingKey!, updatedRecording);
        } else {
          const key = await RecordingManager.createRecording(updatedRecording);
          if (key) {
            RecordingStore.addRecording(updatedRecording);
          } else {
            console.error('RecordingEditor: Failed to create new recording');
            throw new Error('RecordingEditor: Failed to create new recording');
          }
        }
      } catch (recordingError) {
        console.error('RecordingEditor: Error updating/creating recording:', recordingError);
        throw recordingError;
      }

      // Update tags
      logs.push('Updating tags...');
      try {
        // Filter out empty tags
        const filteredAuthorTags = authorDetails.authorTags.filter(tag => tag.trim() !== '');
        const filteredSubjectTags = recordingDetails.subjectTags.filter(tag => tag.trim() !== '');
        const filteredGenreTags = recordingDetails.genreTags.filter(tag => tag.trim() !== '');


        if (isEditing) {
          await updateTags(
            recording!,
            filteredAuthorTags,
            filteredSubjectTags,
            filteredGenreTags,
            pinKey,
            initialAuthorTags
          );
        } else {
          // For new recordings
          if (filteredAuthorTags.length > 0 || filteredSubjectTags.length > 0 || filteredGenreTags.length > 0) {
            await addTagsToNewRecording(
              updatedRecording,
              filteredAuthorTags,
              filteredSubjectTags,
              filteredGenreTags,
              pinKey
            );
          }
        }

        // After save, update the initialAuthorTags
        setInitialAuthorTags(filteredAuthorTags);
        setAuthorDetails(prevDetails => ({
          ...prevDetails,
          authorTags: filteredAuthorTags
        }));

      } catch (tagError) {
        logs.push(`Error updating tags: ${tagError}`);
        // Continue with the save process even if tag update fails
      }

      // Update project audio size
      logs.push('Updating project audio size...');
      if (recordingDetails.fileSize && selectedProject) {
        await ProjectManager.updateProjectAudioSize(selectedProject, recordingDetails.fileSize);
      }

      // Send email with logs
      logs.push('Sending email notification...');
      if (sourceView === 'Form' && selectedProject) {
        const locationDesc = markerPosition 
          ? `Latitude: ${markerPosition.lat}, Longitude: ${markerPosition.lng}`
          : locationDescription;

        // Additional information to include in the email
        const additionalInfo = `
          User: ${authenticationStore.user?.email || 'Unknown'},
          Author: ${authorDetails.authorName || 'Unknown'},
          Recording Title: ${recordingDetails.title || 'Untitled'},
          Project ID: ${selectedProject || 'Unknown'}
        `;

        // Combine additional information with logs
        const emailBody = `
          The form has been submitted with the following details:
          - Location Description: ${locationDesc}
          - Additional Info: ${additionalInfo}
          - Logs: ${logs.join('\n')}
        `;

        await sendEmailNotification(locationDesc, emailBody);
      }

      if (onRecordingAdded) {
        onRecordingAdded();
      }

      logs.push('Executing save callbacks...');
      try {
        if (onRecordingAdded) {
          onRecordingAdded();
        }

        if (onSave && isEditing) {
          await onSave(updatedRecording);
        }
      } catch (callbackError) {
        logs.push(`Error in save callbacks: ${callbackError}`);
        // Don't throw this error, as the main save operation has completed
      }

      logs.push('Save successful, closing editor');
      setSaveSuccess(true);
      onClose();

      // Check if transcription or timing data is missing
      if (!updatedRecording.transcription || Object.keys(updatedRecording.timingData || {}).length === 0) {
        logs.push('Initiating transcription...');
        const filePathToTranscribe = newRecordingPath || updatedRecording.file?.recordingPath;
        if (filePathToTranscribe) {
          initiateTranscription({
            fileName: filePathToTranscribe,
            recordingId: updatedRecording.key,
            transcriptionText: updatedRecording.transcription || '',
            timingData: updatedRecording.timingData || {}
          }).catch(error => {
            console.error('RecordingEditor: Error initiating transcription:', error);
            logs.push(`Error initiating transcription: ${error}`);
          });
        } else {
          console.warn('RecordingEditor: No file path available for transcription');
          logs.push('No file path available for transcription');
        }
      }

    } catch (error) {
      logs.push(`Error in save process: ${error}`);
      console.error('RecordingEditor: Error in save process:', error);
      setError('An error occurred while saving the recording. Please try again.'); // Set error state

      // Send email with error logs
      const errorEmailBody = `
        An error occurred during the save process:
        - Error: ${error}
        - Logs: ${logs.join('\n')}
      `;
      await sendEmailNotification('Save Error Notification', errorEmailBody);
    } finally {
      setIsUploading(false);
    }
  };

  const handleDeleteRecording = async () => {
    if (isEditing && recordingKey && recording) {
      await handleDelete(
        recordingKey,
        pinKey,
        onClose,
        setIsUploading,
        recording,
        recordingDetails.fileSize
      );
    }
  };

  const resetForm = useCallback(() => {
    setSelectedButton('mp3');
    setRecordingDetails({
      recordingURL: '',
      fileSize: undefined,
      fileName: undefined,
      transcription: '',
      title: '',
      description: '',
      narrator: '',
      subjectTags: [],
      genreTags: [],
      whereQRFind: '',
      selectedButton: 'mp3',
    });
    setQrPath('');
    setLocationDescription('');
    if (!isEditing) {
      setAuthorDetails({
        authorName: '',
        authorBio: '',
        authorWebsite: '',
        authorTags: [],
        authorImageUrl: '/assets/icons/user.svg',
        userKey: '',
      });
    }
    if (retainedAuthorDetails) {
      setAuthorDetails(retainedAuthorDetails);
    }
  }, [isEditing, retainedAuthorDetails]);

  const handleRetainArtistDetails = useCallback((retain: boolean) => {
    if (retain) {
      setRetainedAuthorDetails(authorDetails);
    } else {
      setRetainedAuthorDetails(null);
    }
    setSaveSuccess(false);
  }, [authorDetails]);

  useEffect(() => {
    if (!saveSuccess) {
      resetForm();
    }
  }, [saveSuccess, resetForm]);

  const setAuthorTags = useCallback((newTags: string[] | ((prevTags: string[]) => string[])) => {
    setAuthorDetails(prevDetails => ({
      ...prevDetails,
      authorTags: typeof newTags === 'function' ? newTags(prevDetails.authorTags) : newTags
    }));
  }, []);

  const setSubjectTags = useCallback((newTags: string[] | ((prevTags: string[]) => string[])) => {
    setRecordingDetails(prevDetails => ({
      ...prevDetails,
      subjectTags: typeof newTags === 'function' ? newTags(prevDetails.subjectTags) : newTags
    }));
  }, []);

  const setGenreTags = useCallback((newTags: string[] | ((prevTags: string[]) => string[])) => {
    setRecordingDetails(prevDetails => ({
      ...prevDetails,
      genreTags: typeof newTags === 'function' ? newTags(prevDetails.genreTags) : newTags
    }));
  }, []);

  useEffect(() => {
    if (recording) {
      setAuthorDetails({
        authorName: recording.author?.name || '',
        authorBio: recording.author?.bio || '',
        authorWebsite: recording.author?.website || '',
        authorTags: recording.author?.tags.authorTags || [],
        authorImageUrl: recording.author?.image ? constructUrl(recording.author.image) : '/assets/icons/user.svg',
        userKey: recording.author?.userKey || '',
      });
      setRecordingDetails({
        recordingURL: recording.file?.audioURL || '',
        fileSize: recording.file?.size,
        fileName: recording.file?.recordingPath,
        transcription: recording.transcription || '',
        title: recording.file?.title || '',
        description: recording.file?.description || '',
        narrator: recording.ownership?.recordingNarrator || '',
        subjectTags: recording.tags?.subjectTags || [],
        genreTags: recording.tags?.genreTags || [],
        whereQRFind: recording.whereQRFind || '',
        selectedButton: recording.file?.audioURL ? 'url' : 'mp3',
      });
      setInitialAuthorTags(recording.author?.tags.authorTags || []);
    }
  }, [recording]);

  useEffect(() => {
    if (prefillAuthorData && sourceView === 'Form') {
      setAuthorDetails({
        authorName: prefillAuthorData.name || '',
        authorBio: prefillAuthorData.bio || '',
        authorWebsite: prefillAuthorData.website || '',
        authorTags: prefillAuthorData.tags?.authorTags || [],
        authorImageUrl: prefillAuthorData.image ? constructUrl(prefillAuthorData.image) : '/assets/icons/user.svg',
        userKey: prefillAuthorData.userKey || '',
      });
    }
  }, [prefillAuthorData, sourceView]);

  const handleMarkerPositionChange = useCallback((position: L.LatLng | null) => {
    setMarkerPosition(position);
  }, []);

  return (
    <div className={sourceView === 'WanderView' ? "edit-wander" : (sourceView === 'Form' ? "edit-recording form-view" : "edit-recording")}>
      <h1 className="edit-recording1">{isEditing ? 'Edit Recording' : 'Add Recording'}</h1>
      {error && <div className="error-message">{error}</div>} {/* Display error message */}
      <RecordingForm
        sourceView={sourceView}
        config={config}
        authorDetails={authorDetails}
        recordingDetails={{
          ...recordingDetails,
          selectedButton: selectedButton,
        }}
        locationDescription={locationDescription}
        pinType={pinType}
        isUploading={isUploading}
        onAuthorDetailsChange={handleAuthorDetailsChange}
        onRecordingDetailsChange={handleRecordingDetailsChange}
        onLocationDescriptionChange={handleLocationDescriptionChange}
        onCancel={handleCancel}
        onSave={handleSave}
        onDelete={handleDeleteRecording}
        showDelete={isEditing}
        saveSuccess={saveSuccess}
        setSaveSuccess={setSaveSuccess}
        resetForm={resetForm}
        setRetainArtistDetails={handleRetainArtistDetails}
        availableGenreTags={availableGenreTags}
        setAuthorTags={setAuthorTags}
        setSubjectTags={setSubjectTags}
        setGenreTags={setGenreTags}
        projectIconUrl={projectIconUrl}
        onMarkerPositionChange={handleMarkerPositionChange}
        error={error}
      />
      {!skipAuthCheck && showAuthModal && (
        <AuthModal
          onClose={() => setShowAuthModal(false)}
          onAuthSuccess={() => {
            setShowAuthModal(false);
            authenticationStore.setUser(authenticationStore.user);
            handleSave();
          }}
          authorName={authorDetails.authorName}
        />
      )}
    </div>
  );
});

export default RecordingEditor;